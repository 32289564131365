import pattern from "./pattern.png";
import "./App.css";
import logo from "./modo_logo.png";
import patron from "./patron_2.png";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import footerPattern from "./pattern.png";
import letrero from "./letrero.png";
import manita from "./manita.png";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
function App() {
  const originalWidth = 934.4;
  const originalHeight = 920;

  const originalWidth2 = 1099.9;
  const originalHeight2 = 500;

  const defaultSubtitleColor = "#06CDD8";

  const aspectRatio = originalWidth / originalHeight;
  const [subtitleColor, setSubtitleColor] = useState(defaultSubtitleColor);

  const aspectRatio2 = originalWidth2 / originalHeight2;
  const [compra, setCompra] = useState("00.000");
  const [venta, setVenta] = useState("00.000");
  const [changeView, setChangeView] = useState(true);

  const [editFooterImage, setEditFooterImage] = useState(false);
  const [footerImage, setFooterImage] = useState(footerPattern);
  const [bakgroundSize, setBakgroundSize] = useState("cover");
  const [backgroundRepeat, setBackgroundRepeat] = useState("no-repeat");

  const [editSubtTitle, setEditSubtTitle] = useState(false);
  const [firstSubtitle, setFirstSubtitle] = useState("JUN/23/2024");
  const [secondSubtitle, setSecondSubtitle] = useState("Y TRANSPARENTE");
  const [editFirstSubtitle, setEditFirstSubtitle] = useState(firstSubtitle);
  const [editSecondSubtitle, setEditSecondSubtitle] = useState(secondSubtitle);

  const [editFooter, setEditFooter] = useState(false);
  const [firstFooter, setFirstFooter] = useState(
    "Muestra esta historia y que nos sigues en nuestras redes sociales para obtener este tipo de cambio"
  );
  const [secondFooter, setSecondFooter] = useState("");
  const [editFirstFooter, setEditFirstFooter] = useState(firstFooter);
  const [editSecondFooter, setEditSecondFooter] = useState(secondFooter);

  const [password, setPassword] = useState("M123m456!");
  const [passwordInput, setPasswordInput] = useState("");
  let subtitle;

  const [dimensions, setDimensions] = useState({
    width: originalWidth2,
    height: originalHeight2,
  });

  const changeSubtTitle = () => {
    if (passwordInput === password) {
      setFirstSubtitle(editFirstSubtitle);
      setSecondSubtitle(editSecondSubtitle);
      setEditFirstSubtitle(editFirstSubtitle);
      setEditSecondSubtitle(editSecondSubtitle);
      closeModalSubtitle();
    } else {
      alert("Contraseña incorrecta");
      setPasswordInput("");
      setSubtitleColor(defaultSubtitleColor);
    }
  };

  const changeFooter = () => {
    if (passwordInput === password) {
      setFirstFooter(editFirstFooter);
      setSecondFooter(editSecondFooter);
      setEditFirstFooter(editFirstFooter);
      setEditSecondFooter(editSecondFooter);
      closeModalFooter();
    } else {
      alert("Contraseña incorrecta");
      setPasswordInput("");
    }
  };

  const updateDimensions = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let newWidth, newHeight;

    if (windowWidth / aspectRatio < windowHeight) {
      setChangeView(true);
      newWidth = windowWidth * 0.865;
      newHeight = newWidth / aspectRatio;
    } else {
      setChangeView(false);
      newHeight = windowHeight * 0.45;
      newWidth = newHeight * aspectRatio2;
    }

    setDimensions({ width: newWidth, height: newHeight });
  };

  const openModal = () => {
    setEditSubtTitle(true);
    setEditFirstSubtitle(firstSubtitle);
    setEditSecondSubtitle(secondSubtitle);
  };

  const openModalFooter = () => {
    setEditFooter(true);
    setEditFirstFooter(firstFooter);
    setEditSecondFooter(secondFooter);
  };

  const closeModalSubtitle = () => {
    setEditSubtTitle(false);
    setEditFirstSubtitle(firstSubtitle);
    setEditSecondSubtitle(secondSubtitle);
    setPasswordInput("");
  };

  const closeModalFooter = () => {
    setEditFooter(false);
    setEditFirstFooter(firstFooter);
    setEditSecondFooter(secondFooter);
    setPasswordInput("");
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="flex flex-col bg-[red]" id="yourAppElement">
      <Modal
        isOpen={editSubtTitle}
        onRequestClose={closeModalSubtitle}
        style={customStyles}
        contentLabel="Cambio de subtitulo"
      >
        <h2 className="text-[black] font-bold text-3xl mb-5">
          Cambio de fecha
        </h2>
        <div className="flex gap-3 flex-col">
          <div>
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Fecha
            </label>
            <input
              value={editFirstSubtitle}
              onChange={(e) => setEditFirstSubtitle(e.target.value)}
              autoComplete="off"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Código
            </label>
            <input
              value={passwordInput}
              type="text"
              onChange={(e) => setPasswordInput(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>
        <div className="flex justify-evenly items-center mt-5">
          <button
            onClick={() => changeSubtTitle()}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
          >
            Aceptar
          </button>
          <button
            onClick={closeModalSubtitle}
            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={editFooter}
        onRequestClose={closeModalFooter}
        style={customStyles}
        contentLabel="Cambio de footer"
      >
        <h2 className="text-[black] font-bold text-3xl mb-5">
          Cambio de footer
        </h2>
        <div className="flex gap-3 flex-col">
          <div>
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              1 Parrafo
            </label>
            <input
              value={editFirstFooter}
              onChange={(e) => setEditFirstFooter(e.target.value)}
              autoComplete="off"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              2 Parrafo
            </label>
            <input
              value={editSecondFooter}
              autoComplete="off"
              onChange={(e) => setEditSecondFooter(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Código
            </label>
            <input
              value={passwordInput}
              type="text"
              onChange={(e) => setPasswordInput(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>
        <div className="flex justify-evenly items-center mt-5">
          <button
            onClick={() => changeFooter()}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
          >
            Aceptar
          </button>
          <button
            onClick={closeModalFooter}
            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
          >
            Cerrar
          </button>
        </div>
      </Modal>
      {changeView ? (
        <div className="flex justify-center items-center h-screen">
          <div
            style={{
              height: "640px",
              width: "360px",
              //margin: "auto",
              backgroundColor: "white",
              position: "relative",
            }}
            className="pt-[52.94px] bg-patron flex justify-center items-center flex-col"
          >
            <div className="flex items-center flex-col pl-[10px] pr-[10px] flex-1">
              <div className="mb-[16.11px] h-[52.94px] w-[169.41px] bg-[#f8f8f8] !border-[#dcdcdc] !border-[3px] rounded-[18px] flex justify-center items-center">
                <img src={logo} className="w-[129.41px]" />
              </div>
              <div className="mb-[11.51px] relative">
                <img src={letrero} className="w-[258.82px]" />
                <div
                  style={{
                    position: "absolute",
                    top: "37px",
                    left: "34px",
                  }}
                >
                  <input
                    type="text"
                    value={compra}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        value = parts[0] + "." + parts.slice(1).join("");
                      }
                      if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                        setCompra(value);
                      }
                    }}
                    className="text-center leading-[68px] text-[67px] rounded-[10px] bg-inherit text-[#fa0301] bigdigis w-[185px] m-auto h-[50px] md-border-[7px]"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "165px",
                    left: "34px",
                  }}
                >
                  <input
                    type="text"
                    value={venta}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        value = parts[0] + "." + parts.slice(1).join("");
                      }
                      if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                        setVenta(value);
                      }
                    }}
                    className="text-center leading-[68px] text-[67px] rounded-[10px] bg-inherit text-[#fa0301] bigdigis w-[185px] m-auto h-[50px] md-border-[7px]"
                  />
                </div>
              </div>
              <div
                className="mb-[11.52px] bg-[#ecf1f8] w-[258.82px] h-[64.62px] !border-[#FFFFFF] !border-[3px] rounded-full flex justify-center items-center flex-col"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <p className="mb-0 gotham text-[#332ba7] text-[20.69px] leading-[22px]">
                  Cambio Honesto y
                </p>
                <p className="mb-0 gotham text-[#06CDD8] text-[20.62px] leading-[22px]">
                  Transparente
                </p>
              </div>

              <img
                src={manita}
                className="w-[98.72px]"
                style={{
                  position: "absolute",
                  left: "0px",
                  bottom: "34.53px",
                }}
              />

              <div
                className="z-50 bg-[#332ba7] w-[258.82px] h-[64.62px] !border-[#FFFFFF] !border-[3px] rounded-full flex justify-center items-center flex-col"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <p className="mb-0 gotham text-[#FFFFFF] text-[14.5px] leading-[16px]">
                  Muestra esta historia hoy
                </p>
                <p className="mb-0 gotham text-[#FFFFFF] text-[14.5px] leading-[16px]">
                  y que nos sigues en redes
                </p>
                <p className="mb-0 gotham text-[#FFFFFF] text-[14.5px] leading-[16px]">
                  para obtener este precio.
                </p>
              </div>

              <div
                style={{
                  position: "absolute",
                  bottom: "16.11px",
                }}
                onClick={() => openModal()}
              >
                <p className="mb-0 text-[8.8px] leading-[10px] text-[#332ba7] gotham">
                  Válido en cualquiera de nuestras ventanillas solo hoy:{" "}
                  {firstSubtitle}
                </p>
              </div>

              {/* <div
                style={{
                  width: 314,
                  height: 280,
                }}
                className="shadow-custom bg-[#332ba7] m-auto rounded-[8%] border-[4px] border-white pt-[15px] pb-[15px] flex flex-col justify-between"
              >
                <div>
                  <p className="text-center gotham text-[white] text-[30px] leading-[30px] mb-2 md-mb-0">
                    COMPRA / BUY
                  </p>
                  <div className="flex justify-center">
                    <input
                      type="text"
                      value={compra}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^\d.]/g, "");
                        const parts = value.split(".");
                        if (parts.length > 2) {
                          value = parts[0] + "." + parts.slice(1).join("");
                        }
                        if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                          setCompra(value);
                        }
                      }}
                      className="text-center leading-[70px] text-[80px] rounded-[10px] bg-[#3B0506] !border-[#06CDD8] !border-[3px] text-[#fa0301] bigdigis w-[260px] m-auto h-[75px] md-border-[7px]"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-center gotham text-[white] text-[30px] leading-[30px] mb-2 md-mb-0">
                    VENTA / SELL
                  </p>
                  <div className="flex justify-center">
                    <input
                      type="text"
                      value={venta}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^\d.]/g, "");
                        const parts = value.split(".");
                        if (parts.length > 2) {
                          value = parts[0] + "." + parts.slice(1).join("");
                        }
                        if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                          setVenta(value);
                        }
                      }}
                      className="text-center leading-[70px] text-[80px] rounded-[10px] bg-[#3B0506] !border-[#06CDD8] !border-[3px] text-[#fa0301] bigdigis w-[260px] m-auto h-[75px] md-border-[7px]"
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="flex justify-center items-center flex-col">
                <div className="flex justify-center items-center flex-col">
                  <span className="mt-4 gotham text-[#06CDD8] text-[32px] font-bold leading-[33px]">
                    CAMBIO HONESTO
                  </span>
                  <span className="gotham text-[#06CDD8] text-[32px] font-bold leading-[33px]">
                    Y TRANSPARENTE
                  </span>
                </div>
                <span className="gotham text-[#332ba7] text-[22px] font-bold leading-[24px] text-center">
                  Sin Comisión ni Cargos Sorpresa
                </span>
                <span className="mt-3 gotham text-[#332ba7] text-[13px] font-bold leading-[15px] text-center">
                  {firstFooter}
                </span>
              </div> */}
            </div>
            {/* <div className="flex justify-center items-center flex-col">
              <span
                onClick={() => openModal()}
                className="mt-3 gotham text-[#09cdd8] text-[13px] font-bold leading-[15px] text-center"
              >
                {firstSubtitle}
              </span>
              <span className="gotham text-[#b3b3b3] text-[12px] leading-[14px] text-center mb-1  ">
                *Aplican Restricciones
              </span>
            </div> */}
            {/* <div
              className="bg-[#332ba7] h-[calc(100vh*195.91/1920)] prueba w-full"
              style={{
                backgroundImage: `url(${footerImage})`,
                backgroundSize: bakgroundSize,
                backgroundRepeat: backgroundRepeat,
              }}
            ></div> */}
          </div>
        </div>
      ) : (
        <>
          <div className="h-screen flex flex-col">
            <div className="flex-1 justify-center items-center flex flex-col gap-5">
              <div className="flex justify-center items-center flex-col gap-10">
                <div>
                  <img
                    src={logo}
                    className="w-[calc(100vw*723.30/1920)] min-w-[300px]"
                  />
                </div>
                <div
                  style={{
                    width: dimensions.width,
                    height: dimensions.height,
                  }}
                  className="max-[830px]:!w-[90vw] l max-[830px]:min-w-[unset] bg-[#332ba7] rounded-[50px] border-[8px] border-white  flex flex-col justify-evenly items-center min-w-[761px] min-h-[340px]"
                >
                  <div className="flex flex-row justify-evenly items-center w-full">
                    <div>
                      <p className="text-center gotham text-[white] text-[calc(100vw*60/1920)] leading-[calc(100vw*60/1920)] mb-3">
                        COMPRA/BUY
                      </p>
                      <div className="rounded-[10px] bg-[#3B0506] w-[calc(100vw*450/1920)] m-auto h-[calc(100vw*150/1920)] border-[#06CDD8] border-[5px] flex justify-center items-center">
                        <p className="bigdigis m-0 text-[calc(100vw*140.84/1920)] text-[#fa0301] leading-[calc(100vw*140.84/1920)] w-fit">
                          00.000
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-center gotham text-[white] text-[calc(100vw*60/1920)] leading-[calc(100vw*60/1920)] mb-3">
                        VENTA/SELL
                      </p>
                      <div className="rounded-[10px] bg-[#3B0506] w-[calc(100vw*450/1920)] m-auto h-[calc(100vw*150/1920)] border-[#06CDD8] border-[5px] flex justify-center items-center">
                        <p className="bigdigis m-0 text-[calc(100vw*140.84/1920)] text-[#fa0301] leading-[calc(100vw*140.84/1920)] w-fit">
                          00.000
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-center gotham text-[white] text-[calc(100vw*50/1920)] leading-[calc(100vw*50/1920)]">
                      CAMBIA SIN LIMITES
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <span className="text-[#332ba7] text-[calc(100vw*50/1920)] font-bold leading-[calc(100vw*60/1920)]">
                  #ElCambio
                </span>
                <span className="text-[#332ba7] text-[calc(100vw*50/1920)] font-bold leading-[calc(100vw*60/1920)]">
                  EmpiezaEnMODO
                </span>
              </div>
            </div>
            <div className="bg-[white] h-[100px] prueba w-full"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
